import React, { useEffect, useRef } from 'react';
import { Wheel } from "spin-wheel";
import { easeOutCubic } from 'easing-utils';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import Confetti from 'react-confetti'
import axios from 'axios';

function WheelScreen() {

  const [isStarted, setIsStarted] = React.useState(false);
  const [winning, setWinning] = React.useState('');
  const [spinItem, setSpinItem] = React.useState(-1);

  useEffect(() => {

    if (wheel.current) {
      return;
    }

    const items = [{
            label: 'Saldi dovana',
          },
          {
            label: 'Pop-it žaislas',
          },
          {
            label: 'Saldi dovana',
          },
          {
            label: "Čiulptuko laikiklis",
          },
          {
            label: 'Nuolaida',
          },
          {
            label: 'Laikiklis + kramtukas',
          },
          {
            label: 'Nuolaida',
          },
          {
            label: 'Kramtukas-spurga',
          },
          {
            label: 'Saldi dovana',
          },
          {
            label: 'Raktų pakabukas',
          },
          {
            label: 'Nuolaida',
          }, 
          {
            label: 'Raktų pakabukas',
          }]

    const props = {
        name: 'Ohbaby',
        radius: 0.95,
        itemLabelRadius: 0.93,
        itemLabelRadiusMax: 0.35,
        itemLabelRotation: 180,
        itemLabelAlign: "left",
        itemLabelColors: ['#666'],
        itemLabelBaselineOffset: -0.07,
        itemLabelFont: 'Quicksand',
        itemLabelFontSizeMax: 55,
        itemBackgroundColors: ['#f8f3e8', '#e6f8f6', '#f8dbb8', '#cde8e6', '#f6c7b3' ],
        rotationSpeedMax: 500,
        rotationResistance: -100,
        lineWidth: 1,
        lineColor: '#fff',
        image: "./center.png",
        overlayImage: './overlay.svg',
        items,
    
      }
    
    // 2. Decide where you want it to go:
    const container = document.querySelector('.wheel');
    
    // 3. Create the wheel in the container and initialise it with the props:
    wheel.current = new Wheel(container, props);
    wheel.current!.isInteractive = false;

    wheel.current!.onRest = (item: any) => {
        finishedWith(items[item.currentIndex].label);
    }

    document.querySelector('.wheelContainer')!.classList.add('visible');

    const cookieValue = Cookies.get('winning');
    if (cookieValue) {
        setIsStarted(true);
        setWinning(cookieValue);
    }

    getSpinItem();

  }, []);

  const getSpinItem = async () => {

    try {
        const response = await axios.get("https://system.durga.lt:5454/spins");
        setSpinItem(response.data.index);
    } catch (e: any) {
        console.log(e);
    }

  }

  const finishedWith = (text: string) => {

    setWinning(text);
    Cookies.set('winning', text, { expires: 7 });

  }

  const wheel = useRef<any>(null);

  const spin = () => {

    if (spinItem === -1) {
        getSpinItem();
        return
    }

    setIsStarted(true);

    const duration = 4000;
    wheel.current!.spinToItem(spinItem, duration, true, 2, 1, easeOutCubic)

  }

  return (
    <div className="wheelContainer fade-in" style={{position: "relative", flexDirection:"column", display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height:"100vh"}}>
      {winning && <Flex backgroundColor="#ffffff" position={"absolute"} width={'100%'} height={'100%'} alignItems={"center"} justifyContent={'center'}>
        <Flex backgroundColor="#fff" width={"80vw"} maxWidth={"400px"} borderRadius={8} padding={20} alignContent="center" justifyContent="center" flexDir={"column"}>
            <Text color={'#0e546b'} fontSize={40} textAlign={'center'}>Sveikiname!</Text>
            <Text color={'#0e546b'} fontSize={20} textAlign={'center'} mt={-10}> Jūsų prizas: 
                <Text fontWeight={"bold"} fontSize={40} mt={5}>{winning}</Text>
            </Text>
            <Confetti colors={['#f8f3e8', '#e6f8f6', '#f8dbb8', '#cde8e6', '#f6c7b3' ]}/>
        </Flex>
      </Flex>}
      {!isStarted && <Flex backgroundColor="#ffffff22" position={"absolute"} width={'100%'} height={'100%'} alignItems={"center"} justifyContent={'center'}>
        <Button 
            fontWeight={600}
            fontSize={40}
            backgroundColor={"#f6c7b3"}
            onClick={spin}
            color={"#fff"}
            p={40}
            border={"3px solid #fff"} borderRadius={10}>
                Sukti ratą!
            </Button>
      </Flex>}
      <div className="wheel" style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}></div>
    </div>
  );
}

export default WheelScreen;
