import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { Box, Button, Checkbox, Flex, Input, Text } from '@chakra-ui/react';
import Wheel from './wheel';
import axios from 'axios';
import Cookies from 'js-cookie';

function App() {

  const [page, setPage] = useState("game");
  const [email, setEmail] = useState("");
  const [checked, setChecked] = useState(true);
  const [error, setError] = useState("");

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  useEffect(() => {

    if (!isMobile || !isTouchDevice) {
      setPage("not");
      return;
    }

    //const cookieValue = Cookies.get('winning');
    //if (cookieValue) {
       setPage("game");
    //}

  }, []);

  const start = async () => {

    try {
        const response = await axios.post("https://system.durga.lt:5454/email/add", {
          email,
         accepted: checked
        });

        if (response.status !== 200) {
          setError("Įvyko klaida. Bandykite dar kartą.");
          return;
        }

        setPage("game");

    } catch (e: any) {
      setError(e.response.data);
      return;
    }

  }

  if (page === "not") {
    return <div></div>;
  }

  if (page === "game") {
    return <Wheel />
  }

  return (
    <Flex height={"100vh"} width={'100%'} alignItems={'center'} 
          justifyContent={'center'} backgroundColor="#fafafa" flexDirection={"column"}>
            <img src="/logo.png" alt="logo" width={"250px"} style={{marginBottom: "20px"}}/>
        <Flex width={"80vw"} maxWidth={'500px'} height={"250px"} 
             backgroundColor="#ffffff" borderRadius={10} p={20} flexDirection={"column"}>
          <Text fontWeight={'600'} color={"#333"}>Įveskite savo el.paštą</Text>
          <Input height="40px" 
                 onChange={(text) => setEmail(text.target.value)}
                 value={email} 
                 borderRadius={8} 
                 border={"1px solid #ccc"} mb={error ? 5 : 20} fontSize={18} pl={15} pr={15} />
          {error && <Text color={"red"} fontSize={12} mb={20}>{error}</Text>}       
          <Checkbox colorScheme={'orange'} 
                    onChange={(e) => setChecked(e.target.checked)}
                    checked={checked}
                    defaultChecked fontSize={13} 
                    color={"#333"}>Sutinku gauti informaciją apie akcijas ir papildomas nuolaidas</Checkbox>
          <Button variant="solid" size="md" mt={20} fontSize={15} fontWeight={600} onClick={start}
                  height={60} borderRadius={8} backgroundColor={"#005068"} borderWidth={0} color={"#fff"}>
            Pradėti žaidimą
          </Button>        
        </Flex>
    </Flex>
  );
}

export default App;
